import { Refresh, VolumeOff, VolumeUp } from '@mui/icons-material';
import { Checkbox, Stack } from '@mui/material';
import Button from '@mui/material/Button';
import React, { useContext, useEffect, useState } from 'react';
import myFunctions from '../../../../myFunctions';
import { BuisListContext } from '../../../MyContexts/BuisList_Context';
import { TheMasterContext } from '../../../MyContexts/Master_Context';
import { UserContext } from '../../../MyContexts/Users_Context';
import ReportTypeSelect from '../../../MySections/DTechComps/ReportTypeSelect';
import BetweenDates from '../../../MySections/TimeSections/BetweenDates';
import BuisSelect from './BuisSelect';



const BuisTollBarMobi = (props) => {
    const [user] = useContext(UserContext);
    const [buisLst, setBuisLst] = useContext(BuisListContext);
    const [theMaster] = useContext(TheMasterContext);

    const [soundCheckd, setSoundCheckd] = useState(props.soundOn);


    useEffect(() => {
        setSoundCheckd(props.soundOn)
    }, [props.soundOn])


    const refreshTheList = (selectedBtn) => {
        props.refreshTheList(selectedBtn)
    }

    const soundChanghed = () => {

        props.setSoundOn(!soundCheckd);
    }
    return (
        <div className={"buisTollBar buisTollBarMobi"}>

            {(theMaster.blockDeliverys?.block) ? <div className={"errorLine"}><p>מנהל המערכת חסם את המשלוחים לכלל העסקים</p></div> : ""}
            {(theMaster.blockPickUps?.block) ? <div className={"errorLine"}><p>מנהל המערכת חסם את האיסופים לכלל העסקים</p></div> : ""}
            {
                (user.permiss === "admin") &&
                <>
                    {(theMaster.timingDeliverys
                        && theMaster.timingDeliverys > 0) ? <div className={"errorLine"}><p>{`יש הגדרת זמן משלוח למינימום ${theMaster.timingDeliverys} דק' לכלל העסקים`}</p></div> : ""}
                    {(theMaster.minTimingDeliverys && theMaster.minTimingDeliverys > 0) ? <div className={"errorLine"}><p>{`יש תוספת זמן משלוח של ${theMaster.minTimingDeliverys} דק' לכלל העסקים`}</p></div> : ""}
                    {theMaster.masterPaymentsType && theMaster.masterPaymentsType !== "noSetting" && <div className={"errorLine"}>
                        <p>{`יש הגדרת אופן תשלום של ${theMaster.masterPaymentsType === "onlyCredit" ? "רק אשראי" :
                            theMaster.masterPaymentsType === "onlyCash" ? "רק מזומן" : "אשראי + מזומן"} 
                                לכלל העסקים`}</p></div>}
                </>
            }
            <div className={"fullRow"}>
                <div className={"refAndSound"}>
                    <Checkbox
                        icon={<Refresh sx={{ color: 'white' }} />}
                        checkedIcon={<Refresh sx={{ color: 'white' }} />}
                        sx={{}}
                        className={"RefreshBtn"}
                        onClick={() => {
                            refreshTheList(props.selectedBtn);
                        }}
                    />
                    <Checkbox
                        value={soundCheckd}
                        checked={soundCheckd}
                        onChange={() => { soundChanghed() }}
                        checkedIcon={<VolumeUp sx={{ color: 'white' }} />}
                        icon={<VolumeOff sx={{ color: 'white' }} />}
                        sx={{}}
                        className={"soundONOF"}
                    />
                </div>
                <div className={"searchWrap onRight"}>
                    <div className="spaceButtomBig ms-form-group my-0 mb-0 has-icon fs-14">
                        <input type="number" className="dtSearchInput ms-form-input" id="dtSearchInput1" name="dtSearchInput"
                            placeholder="מספר הזמנה" value={props.srchText1} onChange={(e) => props.setSrchText1(e.target.value)}
                            onKeyUp={(e) => props.checkIfEnter(e, "orderNUM")}
                        />
                        <Button className="dtSearchIcon flaticon-search text-disabled" onClick={() => props.searchOrder("orderNUM")} />
                    </div>
                </div>
                <div className={"searchWrap onRight"}>
                    <div className="spaceButtomBig ms-form-group my-0 mb-0 has-icon fs-14">
                        <input type="text" className="dtSearchInput ms-form-input" id="dtSearchInput2" name="dtSearchInput"
                            placeholder="טלפון או שם לקוח" value={props.srchText2} onChange={(e) => props.setSrchText2(e.target.value)}
                            onKeyUp={(e) => props.checkIfEnter(e, "byAll")}
                        />
                        <Button className="dtSearchIcon flaticon-search text-disabled" onClick={() => props.searchOrder("byAll")} />
                    </div>
                </div>
            </div>

            {(user.permiss === "admin") ?
                <div className={"fullRow"}>
                    <BuisSelect busissList={buisLst}
                        defaultValue={myFunctions.getBuisNameByIdInList(buisLst, props.userBuis)}
                        firstHead={"כל העסקים"}
                        clss={"buisSelectOnTBARMobi onLeft noMargin"}
                        userSelection={props.userBuis ? props.userBuis : -1}
                        setUserBuis={(newVal) => { props.setUserBuis(newVal) }}
                    />
                </div> : ""}

            <div className={"fullRow"}>

                <Stack direction="row" spacing={2}>


                    <div className={"ReportTypeSelect btnMnuTop onRight badge badge-primary"}>

                        <ReportTypeSelect selectedRepo={(selec) => refreshTheList(selec)}
                            showAllOpen={(selec) => props.showAllOpen(selec)}
                            reportType={props.selectedBtn}
                        />

                        <div className={`spaceTop backWhite ${(props.showBetweenDts) ? "" : "hideMe"}`}>
                            <div className={"onRight"}>
                                {(props.showBetweenDts) ? <BetweenDates
                                    startDTS={props.startDTS} setStartDTS={(dt) => props.setStartDTS(dt)}
                                    endDTS={props.endDTS} setEndDTS={(dt) => props.setEndDTS(dt)} /> : ""}
                            </div>
                            <div className={"onRight spaceTopBig"}>
                                <Button className={`btnMediumMnu onRight
                     ${props.selectedBtn === 11 ? "selectedMnuBtn" : ""}`} variant="contained" onClick={() => refreshTheList(11)}>
                                    חפש
                                </Button></div>
                        </div>


                    </div>
                </Stack>
            </div>


        </div >
    )
}

export default BuisTollBarMobi;

