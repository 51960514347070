import React, { useContext, useEffect, useState } from 'react'

import { TheMasterContext } from '../../../../MyContexts/Master_Context';
import OpenDelay from '../../../../MySections/DTechComps/OpenDelay';
import utilsUsers from '../../../../../MyConns/_Utils/utilsUsers';
import { TextField } from '@mui/material';


const BlockDelAndPicks = (props) => {
    const [theMaster] = useContext(TheMasterContext);
    const [blockDeliverys, setBlockDeliverys] = useState(theMaster.blockDeliverys);
    const [blockPickUps, setBlockPickUps] = useState(theMaster.blockPickUps);
    const [timingDeliverys, setTimingDeliverys] = useState(theMaster.timingDeliverys || 0);
    const [minTimingDeliverys, setMinTimingDeliverys] = useState(theMaster.minTimingDeliverys || 0);
    const [masterPaymentsType, setMasterPaymentsType] = useState(theMaster.masterPaymentsType);

    useEffect(() => {
    }, [])



    const setBlckDeliverys = () => {
        let temp = { ...blockDeliverys }
        temp.block = !temp.block;
        theMaster["blockDeliverys"] = temp;
        setBlockDeliverys(temp)

    }
    const setBlckPickUps = () => {
        let temp = { ...blockPickUps }
        temp.block = !temp.block;
        theMaster["blockPickUps"] = temp;
        setBlockPickUps(temp)
    }



    const setTheTimingDeliverys = (val) => {

        theMaster["timingDeliverys"] = val;
        setTimingDeliverys(val)
    }

    const setTheMinTimingDeliverys = (val) => {

        theMaster["minTimingDeliverys"] = val;
        setMinTimingDeliverys(val)
    }


    const setTheMasterPaymentsType = (val) => {
       
        theMaster["masterPaymentsType"] = val;
        setMasterPaymentsType(val)
    }

    return (
        <>
            <div className={"col-md-12 mb-2 fullRow grayFrame"}>
                <div className={"col-md-6 mb-2 onRight"}>
                    <label htmlFor={"validationCustom1_6"}>חסימת כלל המשלוחים</label>
                    <span className={"explanation mediumst fullROw"}>במידה ופעיל, לא יתאפשרו הזמנת משלוחים לכלל העסקים</span>
                    <span className={"explanation mediumst fullROw"}>במידה וכבוי, יתאפשרו הזמנת משלוחים לכל העסקים שבהם יש אפשרות משלוחים</span>
                    <label className="ms-switch">
                        <input type="checkbox" id={"validationCustom5_3"} checked={blockDeliverys.block}
                            onChange={(e) => setBlckDeliverys()} />
                        <span className="ms-switch-slider ms-switch-primary round" />
                    </label> <span className={"spHead"}>משלוחים</span>
                </div>

                <div className={"col-md-6 mb-2 onRight"}>
                    <label htmlFor={"validationCustom1_6"}>חסימת כלל האיסופים</label>
                    <span className={"explanation mediumst fullROw"}>במידה ופעיל, לא יתאפשרו הזמנת איסופים לכלל העסקים</span>
                    <span className={"explanation mediumst fullROw"}>במידה וכבוי, יתאפשרו הזמנת איסופים לכל העסקים שבהם יש אפשרות איסוף</span>
                    <label className="ms-switch">
                        <input type="checkbox" id={"validationCustom5_3"} checked={blockPickUps.block}
                            onChange={(e) => setBlckPickUps()} />
                        <span className="ms-switch-slider ms-switch-primary round" />
                    </label> <span className={"spHead"}>איסופים</span>
                </div>

            </div >
            <div className={"col-md-12 mb-2 fullRow grayFrame"}>

                <div className={"col-md-6 mb-2 onRight"}>

                    <label htmlFor={"validationCustom1_6"}>שינוי זמני משלוח לכלל העסקים</label>
                    <span className={"explanation mediumst fullROw"}>במידה ולא 0, זמני המשלוח לכלל העסקים יהיה מינימום הדקות שמצויין</span>
                    <span className={"explanation mediumst fullROw"}>במידה ו0, זמן המשלוח יהיה בהתאם למוגדר בעסק</span>
                    <div className={"col-md-6 mb-2 noPadding spaceMargeTopbig onRight"}>
                        <TextField type="number" inputProps={{ min: "0", step: "1" }} className={"txtField"} id="validationCustom5_334"
                            size="small" label="זמן משלוח (דק')" value={timingDeliverys}
                            onChange={(e) => setTheTimingDeliverys(e.target.value)} />
                    </div>

                </div>
                <div className={"col-md-6 mb-2 onRight"}>

                    <label htmlFor={"validationCustom1_6"}>הוספת דקות לזמן משלוח לכל עסק </label>
                    <span className={"explanation mediumst fullROw"}>במידה ולא 0, יתווספו הדקות לזמן המשלוח המוגדר בכל עסק</span>
                    <span className={"explanation mediumst fullROw"}>במידה ו0 וזמני משלוח לכלל העסקים גם 0, זמן המשלוח יהיה בהתאם למוגדר בכל עסק</span>
                    <div className={"col-md-6 mb-2 noPadding spaceMargeTopbig onRight"}>
                        <TextField type="number" inputProps={{ min: "0", step: "1" }} className={"txtField"} id="validationCustom5_335"
                            size="small" label="זמן משלוח (דק')" value={minTimingDeliverys}
                            onChange={(e) => setTheMinTimingDeliverys(e.target.value)} />
                    </div>

                </div>

            </div>

            <div className={"col-md-12 mb-2 fullRow grayFrame"}>

                <div className={"col-md-6 mb-2 onRight"}>

                    <label htmlFor={"validationCustom1_6"}>הגדרת אופן תשלום</label>
                    <span className={"explanation mediumst fullROw"}>במידה ומוגדר, אופן התשלום לכלל העסקים יהיה לפי ההגדרה</span>
                    <div className={"col-md-6 mb-2 noPadding spaceMargeTopbig onRight"}>
                        <select className="form-control" name="reminder-time"
                            onChange={(e) => setTheMasterPaymentsType(e.target.value)}
                            value={masterPaymentsType}>
                            <option value="noSetting">לפי העסק</option>
                            <option value="onlyCredit">רק אשראי</option>
                            <option value="onlyCash">רק מזומן</option>
                            <option value="creditAndCash">אשראי + מזומן</option>
                        </select>

                    </div>

                </div>


            </div>


        </>

    );
}

export default BlockDelAndPicks;